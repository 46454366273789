import React, { Suspense, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

import { Loading, NotFoundPage, Footer } from 'bumblebee-ui';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import GoogleTranslate from '../components/GoogleTranslate';
import LandingPageBanner from '../components/LandingPageBanner';
import NavBar from '../components/NavBar';
import Resources from '../components/static/from-config/Resources';
import Testimonials from '../components/static/from-config/Testimonials';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PassEligibleRoute,
  PreScreenRoute,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

import { useLogoutMutation } from '../redux/reducers/auth';
import { useGetLandingPageQuery } from '../redux/reducers/config';
import { useLazyDownloadTemplateDocumentQuery } from '../redux/reducers/documents';
// import Footer from '../components/Footer'; // ! SWAP WITH BBUI AFTER SPRINT 65

const config = stateConfig;

// AUTH
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));
const TwoFactor = lazyWithRetry(() => import('../components/auth/TwoFactor'));
const ResendInvitation = lazyWithRetry(() => import('../components/auth/ResendInvitation'));

// APPLICATION
const Dashboard = lazyWithRetry(() => import('../components/applications/Dashboard'));
const Applicant = lazyWithRetry(() => import('../components/applications/pages/Applicant'));
const CoApplicant = lazyWithRetry(() => import('../components/applications/pages/CoApplicant'));
const Household = lazyWithRetry(() => import('../components/applications/pages/Household'));
const Loan = lazyWithRetry(() => import('../components/applications/pages/Loan'));
const Assistance = lazyWithRetry(() => import('../components/applications/pages/Assistance'));
const Financials = lazyWithRetry(() => import('../components/applications/pages/Financials'));
const Hardship = lazyWithRetry(() => import('../components/applications/pages/Hardship'));

// STATIC
const LandingPage = lazyWithRetry(() => import('../components/static/LandingPage'));
const Prescreening = lazyWithRetry(() => import('../components/static/Prescreening'));
const Ineligible = lazyWithRetry(() => import('../components/static/Ineligible'));
const FAQ = lazyWithRetry(() => import('../components/static/FAQ'));
const ReportFraud = lazyWithRetry(() => import('../components/static/ReportFraud'));
const RequiredDocuments = lazyWithRetry(() => import('../components/static/RequiredDocuments'));
const MessageCenter = lazyWithRetry(() => import('../components/static/MessageCenter'));
const TrusteeInquiry = lazyWithRetry(() => import('../components/static/TrusteeInquiry'));
const Waitlist = lazyWithRetry(() => import('../components/static/Waitlist'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createBrowserHistory();

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [logout] = useLogoutMutation();

  const  { data: landing_page = {} } = useGetLandingPageQuery(undefined, { skip: isLoggedIn });

  const [getTemplateDocuments, { isFetching, isError }] = useLazyDownloadTemplateDocumentQuery();

  const [isTranslatorOpened, setTranslatorOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const observer = new MutationObserver((m) => {
        const [mutation] = m;
        if (mutation && mutation.target.firstElementChild.className === 'skiptranslate') {
          mutation.target.firstElementChild.style.display === 'none'
            ? setTranslatorOpen(false)
            : setTranslatorOpen(true);
        }
      });

      const [target] = document.getElementsByClassName('sidebar-fixed');
      observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    }, 2000);
  }, []);

  return (
    <CustomRouter history={history}>
      <ScrollToTop>
        <div className='container-scroller'>
          {!isLoggedIn && landing_page?.is_banner_visible &&
            <>
              {/* FIXED LANDING PAGE BANNER */}
              <LandingPageBanner message={landing_page.message} />

              {/* INVISIBLE LANDING PAGE BANNER FOR APP WRAPPER TOP SPACING */}
              <div className='landing-page-banner invisible' />
            </>
          }

          <NavBar
            isTranslatorOpened={isTranslatorOpened}
            isLoggedIn={isLoggedIn}
            isBannerVisible={!isLoggedIn && landing_page?.is_banner_visible}
            onClickLogIn={() => history.push('/login')}
            onClickLogOut={logout}
          />

          <AppWrapper>
            <Suspense fallback={<Loading main={true} />}>
              <Routes>
                {/* LANDING */}
                <Route path='/' element={<PublicRoute><LandingPage /></PublicRoute>} />
                <Route path='/ineligible' element={<PublicRoute><Ineligible /></PublicRoute>} />
                <Route path='/prescreening' element={<PreScreenRoute><Prescreening /></PreScreenRoute>} />

                {/* AUTH */}
                <Route path='/login' element={<PublicRoute><LogIn /></PublicRoute>} />
                <Route path='/forgot-password' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
                <Route path='/reset-password/:token?' element={<PublicRoute><ResetPassword /></PublicRoute>} />
                <Route path='/validate/session' element={<PublicRoute><TwoFactor /></PublicRoute>} />
                <Route path='/resend-invitation' element={<PublicRoute><ResendInvitation /></PublicRoute>} />

                {/* SIGN_UP */}
                <Route path='/register' element={<PassEligibleRoute><Register /></PassEligibleRoute>} />

                {/* REGISTER MANUAL ACCOUNT */}
                <Route path='/register/:token?' element={<PublicRoute><Register /></PublicRoute>} />

                {/* APPLICATIONS */}
                <Route path='/application' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path='/application/edit' element={<PrivateRoute><Applicant /></PrivateRoute>} />
                <Route path='/application/co-applicants/:coapplicant_uuid' element={<PrivateRoute><CoApplicant /></PrivateRoute>} />
                <Route path='/application/household/:household_uuid' element={<PrivateRoute><Household /></PrivateRoute>} />
                <Route path='/application/loans/:loan_uuid' element={<PrivateRoute><Loan /></PrivateRoute>} />
                <Route path='/application/assistance/edit' element={<PrivateRoute><Assistance /></PrivateRoute>} />
                <Route path='/application/financials/:type/:uuid' element={<PrivateRoute><Financials /></PrivateRoute>} />
                <Route path='/application/hardship/edit' element={<PrivateRoute><Hardship /></PrivateRoute>} />



                {/* AFR */}
                <Route path='/application/afr/:afr_uuid' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/edit' element={<PrivateRoute><Applicant /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/co-applicants/:coapplicant_uuid' element={<PrivateRoute><CoApplicant /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/household/:household_uuid' element={<PrivateRoute><Household /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/loans/:loan_uuid' element={<PrivateRoute><Loan /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/assistance/edit' element={<PrivateRoute><Assistance /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/financials/:type/:uuid' element={<PrivateRoute><Financials /></PrivateRoute>} />
                <Route path='/application/afr/:afr_uuid/hardship/edit' element={<PrivateRoute><Hardship /></PrivateRoute>} />


                {/* STATIC */}
                {config.faqPage.isVisible && <Route path='/faq' element={<FAQ />} />}
                {config.requiredDocumentsPage.isVisible && <Route path='/required-documents' element={<RequiredDocuments />} />}
                {config.reportFraudPage.isVisible && <Route path='/report-fraud' element={<ReportFraud />} />}

                {config.trusteeInquiryPage.isVisible && <Route path='/trustee' element={<PublicRoute><TrusteeInquiry /></PublicRoute>} />}
                {config.waitlistPage.isVisible && <Route path='/waitlist' element={<PublicRoute><Waitlist /></PublicRoute>} />}
                <Route path='/message-center' element={<PrivateRoute><MessageCenter /></PrivateRoute>} />

                {/* CONFIG STATIC */}
                {config.staticPages.resources && <Route path='/resources' element={<PublicRoute><Resources /></PublicRoute>} />}
                {config.staticPages.testimonials && <Route path='/testimonials' element={<PublicRoute><Testimonials /></PublicRoute>} />}

                {/* TEST */}
                {environment !== 'production' && <Route path='/test' element={<PublicRoute><TestPage /></PublicRoute>} />}

                <Route path='*' element={<NotFoundPage onClick={() => history.push('/')} />} />
              </Routes>
            </Suspense>
          </AppWrapper>

          <Footer
            onTosClick={() => getTemplateDocuments('TOS')}
            onPpClick={() => getTemplateDocuments('PP')}
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            history={history}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} />
        </div>
      </ScrollToTop>
    </CustomRouter>
  );
};

AppRouter.propTypes = {
};

export default AppRouter;
